<template>
    <b-container fluid>
        <b-row>
            <Breadcrumb titulo="TEMPLATES DE CAMPANHAS - SMS"
                        :items="breadcrumb"/>
        </b-row>
        <b-row class="mx-0 my-3">
            <b-col class="templates-container p-3">
                <b-row>
                    <b-col>
                        <div class="data-field insider-icon border-0 w-50">
                            <input type="text" ref="search-input" v-model="search" placeholder="Buscar template" class="w-100" />
                            <i v-if="!search?.trim()" class="fas fa-search fa-fw" @click="$refs['search-input'].focus()"></i>
                            <i v-else class="fas fa-times fa-fw" @click="search = null"></i>
                        </div>
                    </b-col>
                </b-row>
                <div class="d-flex flex-row-reverse mb-3">
                    <b-button class="rounded-0 button-novo-disparo border-0" @click="criarNovoTemplate">Novo Template</b-button>
                </div>
                <div class="bg-white p-2" style="font-size: 12px; height: calc(100vh - 260px); overflow-y: auto;">
                    <b-table striped hover :busy="loading" :fields="fields" :items="visibleItems" class="my-0">
                        <template #cell(Mensagem)="data">
                            <span v-html="data.item.Mensagem" data-toggle="tooltip" data-placement="top" :title="data.item.Mensagem.replace(/\<(br)\/\>/gi, '\n').trim()"></span>
                        </template>

                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(actions)="data">
                            <i class="fas fa-pen template-send mx-2" style="cursor: pointer;" title="Editar template..." @click="Editar(data.item)"></i>
                            <i class="fas fa-trash template-send mx-2" style="cursor: pointer;" title="Excluir template..." @click="Excluir(data.item.Id, data.item.Assunto)"></i>
                            <i class="fas fa-paper-plane template-send mx-2" style="cursor: pointer;" title="Disparar template..." @click="enviar(data.item)"></i>
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>
        <b-modal ref="modalCriarNovoDiasparo"
                 title="Criar Novo Template"
                 v-model="modalShow">

            <form ref="form">
                <b-row class="styles-inputs">
                    <b-col>
                        <b-form-group invalid-feedback="Assunto obrigatório">

                            <b-form-input required
                                          type="text"
                                          placeholder="Assunto"
                                          v-model="assunto"
                                          style="height: calc(1.5em + 0.75rem + 0px);"
                                          class="rounded-0 form--control size-font-class" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-checkbox v-b-tooltip.hover title="Informe se deseja utilizar o encurtador de link upx.mx" v-model="linkEncurtado"
                                        class="size-font-class-label"
                                        required>
                                Link encurtado
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <div class="container mx-0 mx-0 my-3">
                    <b-row>

                        <div class="d-flex justify-items-center p-1 rounded w-100 styles-inputs whatsweb-message-box">
                            <svg viewBox="0 0 8 13" width="8" height="13">
                                <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
                                <path fill="#99f1ff" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
                            </svg>
                            <b-form-textarea v-model="MensagemDisparo"
                                             :rows="row"
                                             class="w-100 border-0 rounded-0 size-font-class"
                                             required></b-form-textarea>
                        </div>
                    </b-row>
                    <b-row>
                        <span class="legenda px-2 mt-1">{{LegendaAjuda}}</span>
                    </b-row>

                </div>

            </form>

            <template #modal-footer="{ ok, cancel}">
                <div class="w-100">
                    <b-button variant="success"
                              class="float-right mx-2 rounded-0"
                              :disabled="isDadosNaoPreenchidos"
                              @click="Salvar(ok)">
                        Salvar
                    </b-button>
                    <b-button variant="white"
                              class="float-right mx-2"
                              @click="Cancelar(cancel)">
                        Cancelar
                    </b-button>
                </div>
            </template>

        </b-modal>
        <ModalTransmissaoCampanha ref="modal-transmissao" />
        <ModalSelecaoDestinatarios :mandatoryFields="['Telefone']" ref="modal-selecao-destinatarios" />
    </b-container>
</template>

<script>
    import axios from 'axios';
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import ModalTransmissaoCampanha from "@/components/campanhas/ModalTransmissaoCampanha";
    import ModalSelecaoDestinatarios from "@/components/campanhas/ModalSelecaoDestinatarios";

    export default {
        name: "TemplatesSMS",
        components: {
            Breadcrumb,
            ModalTransmissaoCampanha,
            ModalSelecaoDestinatarios
        },
        computed: {
            LegendaAjuda: function () {
                return "Para criar variações, utilize o comando {{'numero'}}, não repetindo o número final. "
                        + "Elas serão definidas no momento do disparo"
            },
            isDadosNaoPreenchidos() {
                if (this.MensagemDisparo) {
                    return this.assunto ? false : true
                }
                return true
            },
            visibleItems() {
                if (!this.items || this.items.length == 0) return [];
                if (this.search?.trim()) return this.items.filter(item => (`${item.Assunto} ${item.Mensagem}`).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(this.search.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()))
                return this.items;
            }
        },
        data() {
            return {
                breadcrumb: [
                    {
                        id: "1",
                        classe: "fas fa-cogs",
                        texto: "Cadastros Básicos",
                        link: "/cadastros-basicos",
                        cor: "#259cd3"
                    },
                    {
                        id: "2",
                        classe: "fa fa-bullhorn",
                        texto: "Campanhas",
                        link: "/campanhas",
                        cor: "#259cd3"
                    },
                    {
                        id: "3",
                        classe: "far fa-comment-alt",
                        texto: "SMS",
                        link: "/campanhas-sms",
                        cor: "#259cd3"
                    }
                ],
                fields: [
                    { key: 'Assunto', label: 'Assunto', sortable: true, class: 'align-middle' },
                    { key: 'Mensagem', label: 'Conteúdo', class: 'w-50' },
                    { key: 'actions', label: '', class: 'align-middle text-center'}
                ],
                items: [],
                modalShow: false,
                linkEncurtado: false,
                MensagemDisparo: '',
                assunto: '',
                setorSelecionado: '',
                row: 1,
                loading: false,
                search: null,
                isEdit: false,
                dataId: '',
                setoresDisponiveis: null
            };
        },
        watch: {
            MensagemDisparo() {
                if (this.MensagemDisparo)
                    this.row = this.MensagemDisparo.split('\n').length;
            }
        },
        created() {
            this.BuscaCampanhaTemplates();
            axios.get('/api/SMS/setores').then(response => {
                this.setoresDisponiveis = response.data;
            });
        },
        methods: {
            async criarNovoTemplate() {
                this.reset();
                this.isEdit = false;
                this.modalShow = true;
            },
            async BuscaCampanhaTemplates() {
                this.loading = true;
                axios.get('/api/campanhas/BuscaCampanhaTemplates/5').then(res => {
                    this.items = res.data
                    this.loading = false;
                }).catch(err => {
                    console.log("Erro:", err)
                    this.loading = false;
                });
            },
            async Salvar(ok) {
                var re = new RegExp('\n', 'gi')

                var data = {
                    Id: this.dataId,
                    Assunto: this.assunto,
                    Tipo: 'Text',
                    Mensagem: this.MensagemDisparo.replace(re, '<br/>'),
                    Canal : 5,
                    BotaoChat: {
                        Titulo: null,
                        Rodape: null
                    },
                    Botoes: null,
                    LinkEncurtado: this.linkEncurtado
                }
                if (!this.isEdit) {
                    axios.post('/api/Campanhas/SalvarCampanhaTemplates', data, { headers: { 'Content-Type': 'application/json' } })
                        .then(res => {
                            this.items = res.data
                        }).catch(err => {
                            console.log('Erro', err);
                        });
                } else {
                    axios.put(`/api/Campanhas/EditarCampanhaTemplates/`, data, { headers: { 'Content-Type': 'application/json' } })
                        .then(res => {
                            this.items = res.data
                        }).catch(err => {
                            console.log('Erro', err);
                        });

                    this.dataId = ''
                }
                this.isEdit = false;
                this.reset()
                ok()
            },

            async Editar(data) {
                console.log(data)
                this.dataId = data.Id;
                this.assunto = data.Assunto;
                this.MensagemDisparo = data.Mensagem.replace(/<(br)+\/>/gi, '\n');
                this.linkEncurtado = data.LinkEncurtado;
                this.isEdit = true;
                this.modalShow = true;
            },
            async Excluir(id, assunto) {
                axios.delete(`/api/SharkWhats/DeleteCampanhaTemplates/${id}`)
                    .then(res => {
                        this.$bvToast.toast(res.data, {
                            title: `${assunto} Deletado`,
                            variant: 'success',
                            solid: true
                        })
                        this.BuscaCampanhaTemplates();
                    }).catch(err => {
                        console.log('Erro', err);
                    });
            },

            async Cancelar(cancel) {
                cancel()
            },

            reset() {
                this.dataId = ''
                this.assunto = null
                this.linkEncurtado = false
                this.MensagemDisparo = null
            },

            enviar(template) {
                console.log(template.LinkEncurtado)
                if (!this.setoresDisponiveis || this.setoresDisponiveis.length == 0) {
                    this.$bvModal.msgBoxOk("Não existe nenhum setor habilitado para o envio de mensagens via SMS.", {
                        title: "Transmissão indisponível",
                        okVariant: "danger"
                    });
                    return;
                }
                this.$refs["modal-selecao-destinatarios"].select().then(selecao => {
                    this.$refs["modal-transmissao"].open({
                        title: template.Assunto,
                        type: template.Tipo.toUpperCase(),
                        content: template.Mensagem,
                        additionalInfo: {
                            linkEncurtado : JSON.stringify(template.LinkEncurtado)
                        }
                        
                    }, selecao, 5);
                });
            },
        }
    }
</script>

<style scoped>
    .breadcrumb {
        background-image: linear-gradient(to bottom, #99f1ff, #99f1ff);
    }

    .templates-container {
        background-color: var(--cinza-2);
        margin-bottom: 70px;
        min-height: calc(100vh - 170px);
    }

        .templates-container > table {
            background-color: #fff;
            overflow-y: auto;
            margin-bottom: 0;
            font-size: 12px;
            padding: 0 7px 7px 7px;
            margin: 8px 0;
            cursor: default;
        }

    .styles-inputs input, select {
        background-color: var(--cinza-2) !important;
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        transition: all .3s ease-in-out;
        width: 100%;
    }

    .styles-inputs textarea {
        resize: none;
        background-color: transparent;
    }

    .whatsweb-message-box {
        background-color: #99f1ff;
        position: relative;
        box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
    }

        .whatsweb-message-box > svg {
            position: absolute;
            top: 0;
            right: -8px;
        }

    .button-novo-disparo {
        font-size: 13px;
        height: 35px;
        background-color: var(--cor-primaria-cliente);
    }

    .legenda {
        font-size: 8px;
        color: #a1a1a1;
    }

    .template-send {
        font-size: 13px;
        cursor: pointer;
        transition: color ease-in-out .3s;
    }

        .template-send:hover {
            color: var(--cor-primaria-cliente);
        }

    .box-chat-button {
        width: 100%;
        height: auto;
    }

    .remove-button-style {
        margin-right: 3%;
    }

    .size-font-class {
        font-size: 12px !important;
    }
    .size-font-class-label {
        font-size: 14px !important;
        align-content: center;
        text-align: right;
        margin-right: 20px !important;
        margin-top: 2px !important;
    }
</style>